import config from "config/config";

const { baseUrl, clientUrl } = config;
/* *********************

********************** */

export default function credentialConfirmation(token) {
  // const { refresh } = getTokens();
  const endpoint = `${baseUrl}/v1/clients/credential/confirm/${token}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
    },
  };
  // console.log("DATI PER QR CHIAMATA", endpoint, options);
  return fetch(endpoint, options)
    .then((response) => {
      // console.log("CONFIRM CREDENTIAL RESPONSE", response);
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response;
    })
    .catch((e) => {
      // eslint-disable-next-line
      console.log("error", e);
    });
}

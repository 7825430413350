import React, { useEffect, useState } from "react";
import moment from "moment";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// APIs
import getPhishing from "api/phishing/getPhishing";

const PhishingDetails = () => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await getPhishing(id);
      console.log(response);
      setData(response);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Title */}
      <h2 className="page-title">{data?.victim?.name || data?.url || ""}</h2>

      {/* Box */}
      <div className="boxes-border-radius" style={{ background: "white", padding: "15px" }}>
        {data ? (
          <>
            <dl style={{ fontSize: "16px" }}>
              <dt style={{ fontWeight: "600" }}>{t("phishing.title")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.title || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.first_seen")}</dt>
              <dd style={{ marginBottom: "15px" }}>
                {moment(data.firstSeen).format("DD/MM/YYYY")}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.last_seen")}</dt>
              <dd style={{ marginBottom: "15px" }}>
                {data.lastSeen ? moment(data.lastSeen).format("DD/MM/YYYY") : "N/A"}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.victim_name")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.victim?.name || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.victim_nation")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.victim?.country || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.url")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.url || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.path")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.path || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.geo_location")}</dt>
              <dd style={{ marginBottom: "15px" }}>
                {data.city || "N/A"}, {data.countryCode || "N/A"}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.nameservers")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.nameservers?.join(", ") || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.ip")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.ip || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.asn")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.asn || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.tls_validity")}</dt>
              <dd style={{ marginBottom: "15px" }}>
                {data.tls?.validityFrom
                  ? moment(data.tls.validityFrom).format("DD/MM/YYYY")
                  : "N/A"}{" "}
                - {data.tls?.validityTo ? moment(data.tls.validityTo).format("DD/MM/YYYY") : "N/A"}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.dom_hash")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.hashes?.dom || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("phishing.text_hash")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.hashes?.text || "N/A"}</dd>

              {data.description ? (
                <>
                  <dt style={{ fontWeight: "600" }}>{t("phishing.description")}</dt>
                  <dd style={{ marginBottom: "15px" }}>{data.hashes?.description || "N/A"}</dd>
                </>
              ) : null}

              {data.intelligence ? (
                <>
                  <dt style={{ fontWeight: "600" }}>{t("phishing.intelligence")}</dt>
                  <dd style={{ marginBottom: "15px" }}>{data.hashes?.intelligence || "N/A"}</dd>
                </>
              ) : null}

              {data.faviconUrl ? (
                <>
                  <dt style={{ fontWeight: "600" }}>{t("phishing.favicon")}</dt>
                  <img src={data.faviconUrl} alt="favison" style={{ maxWidth: "900px" }} />
                </>
              ) : null}

              {data.screenshotUrl ? (
                <>
                  <dt style={{ fontWeight: "600" }}>{t("phishing.screenshot")}</dt>
                  <img src={data.screenshotUrl} alt="favison" style={{ maxWidth: "900px" }} />
                </>
              ) : null}
            </dl>
          </>
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default PhishingDetails;

const admin = {
  en: {
    translation: {
      time: "Time",
      tag: "Tag",
      add: "Add",
      yes: "Yes",
      no: "No",
      elements_displayed: "Elements displayed",
      duplicates_generic: "Duplicates (generic)",
      duplicates_details: "Duplicates (details)",
      time_elapsed: "Time elapsed",
      elements: "Imported elements",
      stealers: "Stealers",
      ransomware: "Ransomware",
      duplicates: "Duplicates",
      duplicates_cc: "Duplicates c/c",
      duplicates_ci: "Duplicates c/i",
      duplicates_ic: "Duplicates i/c",
      duplicates_ii: "Duplicates i/i",
      no_worker_yet: "No worker statistics yet available",
      email: "Email",
      error_email: "Check the email address",
      role: "Role",
      verified_email: "Email verified",
      two_factors: "Two factors",
      app_user: "App user",
      customer: "Customer",
      name: "Name",
      vatid_cf: "VAT ID/FC",
      passwords_domains: "Passwords domains",
      passwords_app_domains: "Passwords app domains",
      cookies_url: "Cookies url",
      cookies_name: "Cookies name",
      cookies_client_url: "Cookies client url",
      cookies_client_name: "Cookies client name",
      cookies_client_user: "Cookies client user",
      password: "Password",
      type: "Type",
      your_new_token: "Your new token",
      close_now: "Close now",
      token_visible_60_seconds: "Your token will be visible for 60 seconds",
      delete: "Delete",
      stealer: "Stealer",
      status: "Status",
      no_requests_yet: "There are no reports requests, yet",
      delete_success_text: "Token deleted succesfully",
      delete_error_text: "Ops, something went wrong, try again later",
      confirmation_delete_text: "Do you really want to delete token for user",
      restore_token: "Refresh token",
      user_updated: "User updated",
      user_updated_text: "User was updated succesfully",
      user_created: "User created",
      user_created_text: "User was created succesfully",
      customer_edited: "Customer updated",
      customer_edited_text: "Customer was updated succesfully",
      customer_created: "Customer creted",
      customer_created_text: "Customer was created succesfully",
      error_create_customer_text: "Something went wrong, please try again soon",
      error_create_customer_text_data: "Please, add the customer's name",
      error_create_user_text:
        "Something went wrong, check that email was not registered and that password has at least one capital letter, one number and at least 8 characters long",
      start_script_domains: "Refresh customers domains",
      worker_started_title: "Worker started",
      worker_started_text: "Worker is now elaborating the new customers domains, it may take time",
      usernames: "Usernames",
      combos: "Combos",
      url_already_in_title: "Content already under control",
      url_already_in_text:
        "It seems like the parameter you want to add to this customer is already present in another customer",
      invalid_email: "Invalid email format",
      passwords_domains_explanation:
        "Domain to look for inside passwords host. Host must match or ends with . + domain added for the customer to trigger",
      usernames_explanation:
        "Username to look for inside passwords users. Username must match, end with . or @ + value entered for the customer to trigger",
      passwords_url_explanation:
        "Url and domain to look for inside passwords url, in this type of research, domain is optional. Without this, client will be triggered if password url includes value entered, otherwise it also checks host matches or ends with . + domain added",
      passwords_app_domains_explanation:
        "Domain to look for inside passwords host for apps. For the customer to trigger, credential must have been stolen from an app and host must be the one entered or starts with . + domain entered",
      cookies_simple_explanation:
        "Cookie name and url to look for inside cookies. For the customer to trigger, cookie name must match the one entered and url must be the same or . + url to look for (if wildcard ends with the one entered)",
      cookies_client_explanation:
        "Cookie name, url and user to look for inside cookies. It can be useful to understand if device has been compromised. For the customer to match, cookie name must match the one entered, domain must match the one entered or end with . + url to look for and cookie value must contain user value",
      combos_explanation: "Username or domain to look for inside combos.",
      creditcards: "Credit cards",
      creditcards_explanation:
        "The first digits (up to six) of the credit cards to look for in stealers",
      webhook_url: "Webhook url",
      webhook_auth: "Webhook auth",
      webhook_auth_header: "Webhook auth header",
      webhook_content_type: "Webhook content type",
      wehbooks: "Webhooks",
      ransomware_filters_explenation: "Customer's ransomwares",
      ransomware_victim: "Victim",
      ransomware_gang: "Gang",
      ransomware_country: "Country",
      ransomware_sector: "Sector",
      ransomware_domain: "Domain",
      company_size: "Company size",
      receives_periodic_reports: "Receives periodic reports by email",
      skip_mfa: "Skip MFA",
      must_change_password: "Must change password",
      send_welcome_email: "Send welcome e-mail",
      has_mobile: "Is mobile user",
      has_insurance_service: "Has insurance service",
      expiration: "Contract expiration",
      contract_start: "Contract start",
      ips: "IP",
      ips_explanation: "IPs list to look for in stealers",
    },
  },
  it: {
    translation: {
      time: "Tempo",
      tag: "Tag",
      add: "Aggiungi",
      yes: "Sì",
      no: "No",
      elements_displayed: "Elementi visualizzati",
      duplicates_generic: "Duplicati (generico)",
      duplicates_details: "Duplicati (dettaglio)",
      time_elapsed: "Tempo trascorso",
      elements: "Elementi importati",
      stealers: "Stealer",
      ransomware: "Ransomware",
      duplicates: "Duplicati",
      duplicates_cc: "Duplicati c/c",
      duplicates_ci: "Duplicati c/i",
      duplicates_ic: "Duplicati i/c",
      duplicates_ii: "Duplicati i/i",
      no_worker_yet: "Non ci sono ancora statistiche per il worker",
      email: "Email",
      error_email: "Controlla l'indirizzo email inserito",
      role: "Ruolo",
      verified_email: "Email verificata",
      two_factors: "Due fattori",
      app_user: "Utente app",
      customer: "Cliente",
      name: "Nome",
      vatid_cf: "P.IVA/CF",
      passwords_domains: "Domini passwords",
      passwords_app_domains: "Domini passwords apps",
      cookies_url: "Cookies url",
      cookies_name: "Cookies name",
      cookies_client_url: "Cookies url con user",
      cookies_client_name: "Cookies name con user",
      cookies_client_user: "Cookies user",
      password: "Password",
      type: "Tipo",
      your_new_token: "Il tuo nuovo token",
      close_now: "Chiudi ora",
      token_visible_60_seconds: "Il token rimarrà visibile per 60 secondi",
      delete: "Eliminazione",
      stealer: "Stealer",
      status: "Status",
      no_requests_yet: "There are no reports requests, yet",
      delete_success_text: "Il token è stato eliminato con successo",
      delete_error_text: "Ops, qualcosa è andato storto, riprova a breve",
      confirmation_delete_text: "Vuoi davvero eliminare il token dell'utente",
      restore_token: "Rigenera token",
      user_updated: "Utente aggiornato",
      user_updated_text: "L'utente è stato aggiornato con successo",
      user_created: "Utente creato",
      user_created_text: "L'utente è stato creato con successo",
      customer_edited: "Cliente modificato",
      customer_edited_text: "Il cliente è stato modificato con successo",
      customer_created: "Cliente creato",
      customer_created_text: "Il cliente è stato creato con successo",
      error_create_customer_text: "Qualcosa è andato storto, ti preghiamo di riprovare più tardi",
      error_create_customer_text_data: "Per favore, inserisci il nome del cliente per continuare",
      error_create_user_text:
        "Qualcosa è andato storto, controlla che la mail non sia già stata registrata e che la password contenga almeno una lettera maiuscola ed un numero e sia di almeno 8 caratteri",
      start_script_domains: "Aggiorna domini associati ai clienti",
      worker_started_title: "Il worker è partito",
      worker_started_text:
        "Il worker sta ora elaborando i nuovi domini dei clienti, potrebbe volerci un po'",
      usernames: "Usernames",
      combos: "Combos",
      url_already_in_title: "Contenuto già presente",
      url_already_in_text:
        "Sembra che il parametro che vuoi aggiungere per questo cliente è già presente in un altro cliente",
      invalid_email: "Formato email non valido",
      passwords_domains_explanation:
        "Il dominio da cercare all'interno degli host delle password. Affinché triggheri il cliente, l'host deve essere uguale a quello inserito o terminare con . + dominio inserito",
      usernames_explanation:
        "L'username da cercare all'interno degli utenti delle password. Affinché triggheri il cliente, l'username deve essere uguale a quello inserito, terminare con . + username inserito o terminare con @ + username inserito",
      passwords_url_explanation:
        "Url e dominio da cercare all'interno dell'url delle password, in questo tipo di ricerca il dominio è facoltativo. Se non è presente il dominio nella ricerca, il cliente verrà triggherato se l'url della password contiene la stringa inserita, in caso contrario oltre a questo si controlla anche che l'host sia uguale a quello inserito o termini con . + dominio inserito",
      passwords_app_domains_explanation:
        "Il dominio da cercare all'interno degli host delle password per le app. Affinché triggheri il cliente, la credenziale deve essere stata compromessa da un'app e l'host deve essere uguale a quello inserito o iniziare con . + dominio inserito",
      cookies_simple_explanation:
        "Cookie name e url da cercare all'interno dei cookies. Affinché triggheri il cliente, il cookie name deve essere uguale a quello inserito e l'url deve essere uguale a quello inserito o uguale a . + url da cercare (in caso di wildcard terminare con quello inserito)",
      cookies_client_explanation:
        "Cookie name, url e utente da cercare all'interno dei cookies. Utile per capire se il dispositivo dell'utente sia stato compromesso. Affinché triggheri il cliente, il cookie name deve essere uguale a quello inserito, il dominio deve essere uguale a quello inserito o terminare con . + url da cercare e il cookie value deve contenere il valore inserito come utente",
      combos_explanation: "Username o dominio da cercare all'interno delle combolists",
      creditcards: "Carta di credito",
      creditcards_explanation:
        "Le prime cifre (massimo sei) della carta di credito da cercare all'interno degli stealer",
      webhook_url: "Webhook url",
      webhook_auth: "Webhook auth",
      webhook_auth_header: "Webhook auth header",
      webhook_content_type: "Webhook content type",
      wehbooks: "Webhooks",
      ransomware_filters_explenation: "Ransomware del cliente",
      ransomware_victim: "Vittima",
      ransomware_gang: "Gang",
      ransomware_country: "Paese",
      ransomware_sector: "Settore",
      ransomware_domain: "Dominio",
      company_size: "Dimensione azienda",
      receives_periodic_reports: "Riceve report periodici per email",
      skip_mfa: "Salta MFA",
      must_change_password: "Obbliga al cambio password",
      send_welcome_email: "Invia e-mail di benvenuto",
      has_mobile: "È utente mobile",
      has_insurance_service: "Ha servizio di assicurazione",
      expiration: "Scadenza contratto",
      contract_start: "Inizio contratto",
      ips: "IP",
      ips_explanation: "La lista degli IP da ricercare negli stealers",
    },
  },
};

export default admin;

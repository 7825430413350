const dataBreach = {
  en: {
    translation: {
      date: "Date",
      threat_actor_name: "Threat actor",
      description: "Description",
      victim_nation: "Nation",
      attack_type: "Attack type",
      total_records: "Total records",
      records_found: "Records found",
      columns: "Columns",
      results: "Results",
      details: "Details",
      name: "Name",
      intelligence: "Intelligence",
      page_description: "Your company data appeared in a data leak outside your perimeter.",
    },
  },

  it: {
    translation: {
      date: "Data",
      threat_actor_name: "Threat Actor",
      description: "Descrizione",
      victim_nation: "Nazione",
      attack_type: "Tipo di attacco",
      total_records: "Totale registrazioni",
      records_found: "Registrazioni trovate",
      columns: "Colonne",
      results: "Risultati",
      details: "Dettagli",
      name: "Nome",
      intelligence: "Intelligence",
      page_description:
        "I dati relativi alla vostra azienda comparsi all’interno di una fuga di dati all’esterno del vostro perimetro.",
    },
  },
};

export default dataBreach;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";

// APIs
import getPhishings from "api/phishing/getPhishings";

// MUI
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

const CustomTooltip = (columns) => {
  // eslint-disable-next-line react/destructuring-assignment
  const arr = columns.props;

  return (
    <Tooltip
      title={arr.map((item, j) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={j}
          style={{
            fontSize: "14px",
            background: "white",
            borderRadius: "35px",
            textAlign: "center",
            marginBottom: "3px",
            color: "black",
            padding: "1px 5px",
          }}
          background="white"
        >
          {item}
        </div>
      ))}
    >
      <IconButton>
        <Icon fontSize="small">info</Icon>
      </IconButton>
    </Tooltip>
  );
};

const Phishing = () => {
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      const { results } = await getPhishings();
      setData(results);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Title */}
      <h2 className="page-title">Phishings</h2>

      {/* Page description */}
      <p style={{ fontSize: "small" }}>{t("phishing.page_description")}</p>

      {/* Table */}
      <div className="table_users_list" style={{ maxWidth: "900px", marginTop: "20px" }}>
        <table className="table-content" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>{t("phishing.first_seen")}</th>
              <th style={{ textAlign: "center" }}>{t("phishing.victim_name")}</th>
              <th style={{ textAlign: "center" }}>{t("phishing.victim_nation")}</th>
              <th style={{ textAlign: "center" }}>{t("phishing.url")}</th>
              <th style={{ textAlign: "center" }}>{t("phishing.geo_location")}</th>
              <th style={{ textAlign: "center" }}>{t("phishing.details")}</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0
              ? data.map((phishing) => (
                  <tr key={phishing.id}>
                    <td>{moment(phishing.firstSeen).format("DD/MM/YYYY")}</td>
                    <td>{phishing.victim?.name || "N/A"}</td>
                    <td>{phishing.victim?.country || "N/A"}</td>
                    <td style={{ maxWidth: "300px" }}>{phishing.url || "N/A"}</td>
                    <td>
                      {phishing.countryCode || "N/A"}, {phishing.city || "N/A"}
                    </td>
                    <td>
                      <Link to={`/phishing/${phishing.id}`}>{t("phishing.details")}</Link>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default Phishing;

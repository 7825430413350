import { jwtDecode } from "jwt-decode";

export default function getTokens() {
  const refresh = JSON.parse(localStorage.getItem("refresh"));
  const access = JSON.parse(localStorage.getItem("access"));
  const userid = JSON.parse(localStorage.getItem("userid"));

  const decoded = access?.token ? jwtDecode(access.token) : null;
  const mfa = decoded?.mfaVerified;

  return { refresh, access, userid, mfa };
}

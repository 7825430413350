import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl, clientUrl } = config;
/* *********************

********************** */

export default function refreshTokens() {
  const { refresh } = getTokens();

  const endpoint = `${baseUrl}/v1/auth/refresh-tokens`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      refreshToken: refresh ? refresh.token : "",
    }),
  };

  if (!refresh) return Promise.reject(new Error("NO_TOKENS"));

  return fetch(endpoint, options)
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson && responseJson.access) {
        localStorage.setItem("refresh", JSON.stringify(responseJson.refresh));
        localStorage.setItem("access", JSON.stringify(responseJson.access));
      }

      return responseJson;
    })
    .catch();
}

/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

// Import icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

function ReportsBarChart({ color, chart, ransomwareCounter, ransomwareCountryCounter, country }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const { t } = useTranslation();

  useEffect(() => {
    const ctx =
      document && document.getElementById("home-ransomware-chart")
        ? document.getElementById("home-ransomware-chart").getContext("2d")
        : null;

    let backgroundGradient = null;
    if (ctx) {
      backgroundGradient = ctx.createLinearGradient(0, 0, 0, 100);
      backgroundGradient.addColorStop(0.4, "rgb(220, 58, 246)");
      backgroundGradient.addColorStop(1, "rgb(44, 116, 225)");
    }

    if (data && data.datasets && data.datasets[0]) {
      data.datasets[0].backgroundColor = backgroundGradient;
    }
  }, [data]);

  return (
    <Card sx={{ height: "100%", background: "transparent", boxShadow: "none" }}>
      <MDTypography varialnt="h6" fontSize="medium" fontWeight="bold" pt={2} pl={4}>
        {t(`analytics.ransomware_attacks`)}
      </MDTypography>
      <MDTypography
        variant="caption"
        fontSize="small"
        fontWeight="regular"
        sx={{ color: "#669FF6" }}
        pl={4}
      >
        {t("analytics.last_12_months")}
      </MDTypography>
      <MDBox pt={2} pb={2} pl={4} display="flex">
        <Tooltip title={t("analytics.ransomware_question_mark")}>
          <HelpOutlineIcon className="home-question-mark" />
        </Tooltip>
        {ransomwareCounter >= 0 ? (
          <MDBox width="50%" position="relative">
            <MDTypography
              fontSize="1.5em"
              variant="h3"
              color="dark"
              fontWeight="bold"
              sx={{ lineHeight: "1" }}
            >
              {ransomwareCounter}
              <MDTypography
                variant="caption"
                fontSize="small"
                fontWeight="regular"
                display="block"
                sx={{ color: "#669FF6" }}
              >
                {t(`analytics.worldwide`)}
              </MDTypography>
            </MDTypography>
          </MDBox>
        ) : null}
        {ransomwareCountryCounter >= 0 && country ? (
          <MDBox width="50%">
            <MDTypography
              fontSize="1.5em"
              variant="h3"
              color="dark"
              fontWeight="bold"
              sx={{ lineHeight: "1" }}
            >
              {ransomwareCountryCounter}
              <MDTypography
                variant="caption"
                fontSize="small"
                fontWeight="regular"
                display="block"
                sx={{ color: "#669FF6" }}
              >
                In {t(`countryCode.${country}`)}
              </MDTypography>
            </MDTypography>
          </MDBox>
        ) : null}
      </MDBox>
      <MDBox pt={2} pb={1} pr={5} pl={4}>
        <div>
          <MDTypography varialnt="h6" fontSize="medium" fontWeight="bold">
            {t(`analytics.title_attacks_trend`)}
          </MDTypography>
          <MDTypography
            variant="caption"
            fontSize="small"
            fontWeight="regular"
            display="block"
            sx={{ color: "#669FF6" }}
          >
            {t("analytics.last_12_months")}
          </MDTypography>
        </div>
        {useMemo(
          () => (
            <MDBox variant="gradient" bgColor="white" pt={1} pb={2} height="12rem">
              <Bar id="home-ransomware-chart" data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  ransomwareCounter: 0,
  ransomwareCountryCounter: 0,
  country: "IT",
  // description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  ransomwareCounter: PropTypes.number,
  ransomwareCountryCounter: PropTypes.number,
  country: PropTypes.string,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;

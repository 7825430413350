const reports = {
  en: {
    translation: {
      date: "Date",
      type: "Type",
      details: "Details",
      no_reports_yet: "No requests of reports yet",
      pending: "Pending",
      no_stealer: "No stealer",
      user: "User",
      show: "Show",
      text_box_no_reports:
        "No reports available. You can request detailed reports related to a single incident, as well as customized reports on specific threats or vulnerabilities, threat actors, and much more.",
      text_box_personalized_reports:
        "In addition to detailed reports related to a single incident, you can also request customized reports on specific threats or vulnerabilities, threat actors, and much more.",
      text_box_contact_us:
        "To request a personalized report, you can contact our experts by explaining your specific needs via email at ",
    },
  },
  it: {
    translation: {
      date: "Data",
      type: "Tipo",
      details: "Dettagli",
      no_reports_yet: "Non ci sono ancora richieste di report",
      pending: "In attesa",
      no_stealer: "Nessuno stealer",
      user: "Utente",
      show: "Visualizza",
      text_box_no_reports:
        "Nessun report disponibile. È possibile richiedere dei rapporti di approfondimento relativi a un singolo incidente, ma anche report personalizzati a proposito di minacce o vulnerabilità specifiche, threat actor e molto altro.",
      text_box_personalized_reports:
        "Oltre ai rapporti di approfondimento relativi a un singolo incidente, è possibile richiedere anche report personalizzati a proposito di minacce o vulnerabilità specifiche, threat actor e molto altro.",
      text_box_contact_us:
        "Per richiedere un report personalizzato, potete contattare i nostri esperti spiegando le vostre necessità specifiche alla mail ",
    },
  },
};

export default reports;

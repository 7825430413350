// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import moment from "moment";
import addClient from "api/clients/addClient";
import controlUrlAvailable from "api/clients/controlUrlAvailable";
import getAllSectors from "api/ransomware/getAllSectors";
import getAllGangs from "api/ransomware/getAllGangs";
import addLog from "api/logs/addLog";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import SweetAlert from "react-bootstrap-sweetalert";
import Autocomplete from "layouts/dashboards/stealers/autocomplete";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { validateEmail, validateUrlOrEmail, validateCC } from "utilities/validateEmail";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import countryCode from "config/countryCode";

function AddClient() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [name, setName] = useState("");
  const [vatId, setVatId] = useState("");
  const [expiration, setExpiration] = useState("");
  const [start, setStart] = useState(null);
  const [passwordurl, setPasswordurl] = useState("");
  const [passwordurlError, setPasswordurlError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [creditcard, setCreditcard] = useState("");
  const [creditcardError, setCreditcardError] = useState(false);
  const [combo, setCombo] = useState("");
  const [comboError, setComboError] = useState(false);
  const [pwUrl, setPwUrl] = useState("");
  const [email, setEmail] = useState([]);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [ip, setIp] = useState([]);
  const [errorIp, setErrorIp] = useState(false);
  const [ipInput, setIpInput] = useState("");
  // const [prefix, setPrefix] = useState("");
  // const [phone, setPhone] = useState("");
  const [webhooks, setWebhooks] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookError, setWebhookError] = useState(false);
  const [webhookToken, setWebhookToken] = useState("");
  const [webhookAuthHeader, setWebhookAuthHeader] = useState("");
  const [webhookContentType, setWebhookContentType] = useState("");
  const [pwUrlApp, setPwUrlApp] = useState("");
  const [pwUrlDomain, setPwUrlDomain] = useState("");
  const [cookiename, setCookiename] = useState("");
  const [cookieurl, setCookieurl] = useState("");
  const [cookieurlError, setCookieurlError] = useState(false);
  const [cookieClientName, setCookieClientName] = useState("");
  const [cookieClientUrl, setCookieClientUrl] = useState("");
  const [cookieClientUser, setCookieClientUser] = useState("");
  const [status, setStatus] = useState("active");
  const [service, setService] = useState(["stealer", "ransomware", "combo"]);
  const [serviceName, setServiceName] = useState("premium");
  const [hasInsurance, setHasInsurance] = useState(false);
  const [usernames, setUsernames] = useState([]);
  const [combos, setCombos] = useState([]);
  const [cookies, setCookies] = useState([]);
  const [creditcards, setCreditcards] = useState([]);
  const [cookiesClient, setCookiesClient] = useState([]);
  const [pwUrls, setPwUrls] = useState([]);
  const [pwUrlsApps, setPwUrlsApps] = useState([]);
  const [ransomwares, setRansomwares] = useState([]);
  const [ransomwareVictim, setRansomwareVictim] = useState("");
  const [ransomwareCountry, setRansomwareCountry] = useState("");
  const [ransomwareGang, setRansomwareGang] = useState("");
  const [ransomwareSector, setRansomwareSector] = useState("");
  const [ransomwareDomain, setRansomwareDomain] = useState("");
  const [ransomwareCompanySize, setRansomwareCompanySize] = useState("-");
  const [allSectors, setAllSectors] = useState([]);
  const [allGangs, setAllGangs] = useState([]);
  // eslint-disable-next-line
  const [wildcardPwApp, setWildcardPwApp] = useState(false);
  const [passwords, setPasswords] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [wildcard, setWildcard] = useState(false);
  const [popupUrlAlready, setPopupUrlAlready] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getAllSectors()
      .then((res) => {
        setAllSectors(res);
      })
      // eslint-disable-next-line
    .catch((err) => console.log("error getting sectors", err));
    getAllGangs()
      .then((res) => res.filter((el) => el))
      .then((gangs) => {
        setAllGangs(gangs);
      })
      // eslint-disable-next-line
    .catch((err) => console.log("error getting gangs", err));
  }, []);

  function editService(type) {
    setServiceName(type);
    switch (type) {
      case "premium":
        setService(["stealer", "ransomware", "combo"]);
        break;
      case "stealer":
        setService(["stealer", "ransomware"]);
        break;
      case "combo":
        setService(["combo"]);
        break;
      case "combo_potential":
        setService(["stealer", "ransomware", "combo"]);
        break;
      case "empty":
        setService([]);
        break;
      default:
        setService(["stealer", "ransomware", "combo"]);
    }
  }

  function removePasswords(index) {
    const newArray = [].concat(passwords);
    newArray.splice(index, 1);
    setPasswords(newArray);
  }

  function removeUsernames(index) {
    const newArray = [].concat(usernames);
    newArray.splice(index, 1);
    setUsernames(newArray);
  }

  function removeCombos(index) {
    const newArray = [].concat(combos);
    newArray.splice(index, 1);
    setCombos(newArray);
  }

  function removeCreditcards(index) {
    const newArray = [].concat(creditcards);
    newArray.splice(index, 1);
    setCreditcards(newArray);
  }

  function addPasswords(elem) {
    const newArray = [].concat(passwords);
    newArray.push(elem);
    setPasswords(newArray);
  }

  function addUsernames(elem) {
    const newArray = [].concat(usernames);
    newArray.push(elem);
    setUsernames(newArray);
  }

  function addCombos(elem) {
    const newArray = [].concat(combos);
    newArray.push(elem);
    setCombos(newArray);
  }

  function addCreditcards(elem) {
    const newArray = [].concat(creditcards);
    newArray.push(elem);
    setCreditcards(newArray);
  }

  function removeCookies(index) {
    const newArray = [].concat(cookies);
    newArray.splice(index, 1);
    setCookies(newArray);
  }

  function removeCookiesClient(index) {
    const newArray = [].concat(cookiesClient);
    newArray.splice(index, 1);
    setCookiesClient(newArray);
  }

  function addCookies(elem) {
    const newArray = [].concat(cookies);
    newArray.push(elem);
    setCookies(newArray);
  }

  function addCookiesClient(elem) {
    const newArray = [].concat(cookiesClient);
    newArray.push(elem);
    setCookiesClient(newArray);
  }

  function addPwUrls(elem) {
    const newArray = [].concat(pwUrls);
    newArray.push(elem);
    setPwUrls(newArray);
  }

  function removePwUrls(index) {
    const newArray = [].concat(pwUrls);
    newArray.splice(index, 1);
    setPwUrls(newArray);
  }

  function addPwUrlsApps(elem) {
    const newArray = [].concat(pwUrlsApps);
    newArray.push(elem);
    setPwUrlsApps(newArray);
  }

  function removePwUrlsApps(index) {
    const newArray = [].concat(pwUrlsApps);
    newArray.splice(index, 1);
    setPwUrlsApps(newArray);
  }

  function addEmail(elem) {
    // console.log("vengo qui", elem);
    const newArray = [].concat(email);
    // console.log("newArray", newArray);
    if (!newArray.includes(elem)) {
      newArray.push(elem);
      setEmail(newArray);
    }
  }

  function removeEmail(index) {
    const newArray = [].concat(email);
    newArray.splice(index, 1);
    setEmail(newArray);
  }

  function addIp(elem) {
    const newArray = [].concat(ip);
    if (!newArray.includes(elem)) {
      newArray.push(elem);
      setIp(newArray);
    }
  }

  function removeIp(index) {
    const newArray = [].concat(ip);
    newArray.splice(index, 1);
    setIp(newArray);
  }

  function addRansomware() {
    const newArray = [].concat(ransomwares);
    const elem = {
      victim: ransomwareVictim || "",
      country: ransomwareCountry || "",
      gang: ransomwareGang || "",
      domain: ransomwareDomain || "",
      sector: ransomwareSector || "",
      company_size: ransomwareCompanySize === "-" ? "" : ransomwareCompanySize || ""
    };
    newArray.push(elem);
    setRansomwares(newArray);
    setRansomwareVictim("");
    setRansomwareCountry("");
    setRansomwareGang("");
    setRansomwareDomain("");
    setRansomwareSector("");
    setRansomwareCompanySize("-");
  }

  function removeRansomware(index) {
    const newArray = [].concat(ransomwares);
    newArray.splice(index, 1);
    setRansomwares(newArray);
  }

  const selectSector = (element) => {
    setRansomwareSector(element);
  };

  const selectGang = (element) => {
    setRansomwareGang(element);
  };

  const selectAutocomplete = (element) => {
    setRansomwareCountry(element);
  };

  function addWebhook(elem) {
    const newArray = [].concat(webhooks);
    if (!newArray.includes(elem)) {
      newArray.push(elem);
      setWebhooks(newArray);
    }
  }

  function removeWebhook(index) {
    const newArray = [].concat(webhooks);
    newArray.splice(index, 1);
    setWebhooks(newArray);
  }

  function controlAddition(content) {
    return controlUrlAvailable(content)
      .then((res) => res)
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while checking availability", err);
      });
  }

  function getInput(type, editFunction, defaultValue) {
    if (type === "password") {
      return (
        <div style={{ position: "relative" }}>
          <MDInput
            type={!showPassword ? "password" : "input"}
            label={type}
            fullWidth
            defaultValue={defaultValue}
            onChange={(e) => {
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              size="small"
              disableRipple
            >
              {showPassword ? (
                <Icon fontSize="medium">visibility</Icon>
              ) : (
                <Icon fontSize="medium">lock</Icon>
              )}
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        value={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getInputArray(
    type,
    editFunction,
    addFunction,
    value,
    labelToCheck,
    labelExplanation,
    errorValue = false,
    setErrorValue = () => {},
    controlFunction = validateUrlOrEmail
  ) {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {labelExplanation}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={type}
            fullWidth
            className={errorValue ? "input-error" : ""}
            value={value}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && value && controlFunction(value)) {
                setErrorValue(false);
                controlAddition({
                  type: labelToCheck,
                  content: e.target.value,
                })
                  .then((res) => {
                    if (res) {
                      addFunction(e.target.value);
                      editFunction("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              if (!controlFunction(e.target.value)) {
                setErrorValue(true);
              } else {
                setErrorValue(false);
              }
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (value) {
                  controlAddition({
                    type: labelToCheck,
                    content: value,
                  })
                    .then((res) => {
                      if (res) {
                        addFunction(value);
                        editFunction("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputArrayEmail(
    type,
    editFunction,
    addFunction,
    value,
    labelToCheck,
    labelExplanation
  ) {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {labelExplanation}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={type}
            fullWidth
            value={value}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && value) {
                if (validateEmail(e.target.value)) {
                  setErrorEmail(false);
                  controlAddition({
                    type: labelToCheck,
                    content: e.target.value,
                  })
                    .then((res) => {
                      if (res) {
                        addFunction(e.target.value);
                        editFunction("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                } else {
                  setErrorEmail(true);
                }
              }
            }}
            onChange={(e) => {
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (value && validateEmail(value)) {
                  setErrorEmail(false);
                  controlAddition({
                    type: labelToCheck,
                    content: value,
                  })
                    .then((res) => {
                      if (res) {
                        addFunction(value);
                        editFunction("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                } else {
                  setErrorEmail(true);
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
        {errorEmail ? <div className="email_error">{t("admin.error_email")}</div> : null}
      </>
    );
  }

  function getInputRansomwares() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.ransomware_filters_explenation")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={t("admin.ransomware_victim")}
            fullWidth
            value={ransomwareVictim}
            onKeyDown={async (e) => {
              if (
                e.key === "Enter" &&
                (ransomwareVictim ||
                  ransomwareGang ||
                  ransomwareCountry ||
                  ransomwareSector ||
                  ransomwareDomain)
              ) {
                addRansomware();
              }
            }}
            onChange={(e) => {
              setRansomwareVictim(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div style={{ marginBottom: "30px" }}>
            <Autocomplete
              label={t("admin.ransomware_gang")}
              onSelect={selectGang}
              list={allGangs}
            />
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Autocomplete
              label={t("admin.ransomware_country")}
              onSelect={selectAutocomplete}
              list={Object.keys(countryCode).map((el) => ({
                label: t(`countryCode.${el}`),
                value: el,
              }))}
            />
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Autocomplete
              label={t("admin.ransomware_sector")}
              onSelect={selectSector}
              list={allSectors}
            />
          </div>
          <MDInput
            type="input"
            label={t("admin.ransomware_domain")}
            fullWidth
            value={ransomwareDomain}
            onKeyDown={async (e) => {
              if (
                e.key === "Enter" &&
                (ransomwareVictim ||
                  ransomwareGang ||
                  ransomwareCountry ||
                  ransomwareSector ||
                  ransomwareDomain)
              ) {
                addRansomware();
              }
            }}
            onChange={(e) => {
              setRansomwareDomain(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div style={{ marginBottom: "30px" }}>
            <MDTypography fontSize="small" mb={1} textAlign="left">
              {t("admin.company_size")}
            </MDTypography>
            <select
              onChange={(e) => {
                setRansomwareCompanySize(e.target.value);
              }}
              defaultValue={ransomwareCompanySize}
              className="selectDefault"
              style={{
                width: "100%",
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value="-">-</option>
              <option value="L">{t("companySize.L")}</option>
              <option value="M">{t("companySize.M")}</option>
              <option value="U">{t("companySize.U")}</option>
              <option value="S">{t("companySize.S")}</option>
              <option value="P">{t("companySize.P")}</option>
            </select>
          </div>
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (
                  ransomwareVictim ||
                  ransomwareGang ||
                  ransomwareCountry ||
                  ransomwareSector ||
                  ransomwareDomain
                ) {
                  addRansomware();
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function viewArrayRansomwares() {
    return ransomwares.map((el, index) => (
      <div key={`ransomware-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.victim || "No Victim"} - {el.gang || "No Gang"} - {el.country || "No Country"} -{" "}
        {el.sector || "No Sector"} - {el.domain || "No Domain"} -{" "}
        {el.company_size || "No Company size"}
        <IconButton
          onClick={() => {
            removeRansomware(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function clearWebhookData() {
    setWebhookUrl("");
    setWebhookToken("");
    setWebhookAuthHeader("");
    setWebhookContentType("");
  }

  function getNewWebhook() {
    return {
      url: webhookUrl || "",
      contentType: webhookContentType || "",
      auth: webhookToken || "",
      authHeader: webhookAuthHeader || "",
    };
  }

  function getInputArrayWebhook() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.wehbooks")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={t("admin.webhook_url")}
            fullWidth
            value={webhookUrl}
            className={webhookError ? "input-error" : ""}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
                setWebhookError(false);
              }
            }}
            onChange={(e) => {
              if (!validateUrlOrEmail(e.target.value)) {
                setWebhookError(true);
              } else {
                setWebhookError(false);
              }
              setWebhookUrl(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.webhook_auth")}
            fullWidth
            value={webhookToken}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
              }
            }}
            onChange={(e) => {
              setWebhookToken(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.webhook_auth_header")}
            fullWidth
            value={webhookToken}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
              }
            }}
            onChange={(e) => {
              setWebhookAuthHeader(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.webhook_content_type")}
            fullWidth
            value={webhookContentType}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
              }
            }}
            onChange={(e) => {
              setWebhookContentType(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (webhookUrl && validateUrlOrEmail(webhookUrl)) {
                  const newWebhook = getNewWebhook();
                  addWebhook(newWebhook);
                  clearWebhookData();
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputCookie() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.cookies_simple_explanation")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={t("admin.cookies_url")}
            fullWidth
            value={cookieurl}
            className={cookieurlError ? "input-error" : ""}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieurl && cookiename && validateUrlOrEmail(cookieurl)) {
                const elem = { url: cookieurl, name: cookiename };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookies({ ...elem, wildcard });
                      setCookieurl("");
                      setWildcard(false);
                      setCookiename("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              if (!validateUrlOrEmail(e.target.value)) {
                setCookieurlError(true);
              } else {
                setCookieurlError(false);
              }
              setCookieurl(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.cookies_name")}
            fullWidth
            value={cookiename}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieurl && cookiename && validateUrlOrEmail(cookieurl)) {
                const elem = { url: cookieurl, name: cookiename };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookies({ ...elem, wildcard });
                      setCookieurl("");
                      setWildcard(false);
                      setCookiename("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookiename(e.target.value);
            }}
          />
          <input
            type="checkbox"
            style={{ marginBottom: "30px" }}
            value={wildcard}
            onChange={() => {
              setWildcard(!wildcard);
            }}
          />{" "}
          <span style={{ fontSize: "14px" }}>Cookie wildcard</span>
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (cookiename && cookieurl && validateUrlOrEmail(cookieurl)) {
                  const elem = { url: cookieurl, name: cookiename };
                  controlAddition({
                    type: "cookies",
                    content: elem,
                  })
                    .then((res) => {
                      if (res) {
                        addCookies({ ...elem, wildcard });
                        setCookieurl("");
                        setWildcard(false);
                        setCookiename("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputCookieClient() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.cookies_client_explanation")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={t("admin.cookies_client_url")}
            fullWidth
            value={cookieClientUrl}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieClientUrl && cookieClientName && cookieClientUser) {
                const elem = { url: cookieClientUrl, name: cookieClientName };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookiesClient({ ...elem, user: cookieClientUser });
                      setCookieClientUrl("");
                      setCookieClientName("");
                      setCookieClientUser("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookieClientUrl(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.cookies_client_name")}
            fullWidth
            value={cookieClientName}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieClientUrl && cookieClientName && cookieClientUser) {
                const elem = { url: cookieClientUrl, name: cookieClientName };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookiesClient({ ...elem, user: cookieClientUser });
                      setCookieClientUrl("");
                      setCookieClientName("");
                      setCookieClientUser("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookieClientName(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.cookies_client_user")}
            fullWidth
            value={cookieClientUser}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieClientUrl && cookieClientName && cookieClientUser) {
                const elem = { url: cookieClientUrl, name: cookieClientName };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookiesClient({ ...elem, user: cookieClientUser });
                      setCookieClientUrl("");
                      setCookieClientName("");
                      setCookieClientUser("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookieClientUser(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (cookieClientUrl && cookieClientName && cookieClientUser) {
                  const elem = { url: cookieClientUrl, name: cookieClientName };
                  controlAddition({
                    type: "cookies",
                    content: elem,
                  })
                    .then((res) => {
                      if (res) {
                        addCookiesClient({ ...elem, user: cookieClientUser });
                        setCookieClientUrl("");
                        setCookieClientName("");
                        setCookieClientUser("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputPwUrl() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.passwords_url_explanation")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label="Password url"
            fullWidth
            value={pwUrl}
            onKeyDown={(e) => {
              if (e.key === "Enter" && pwUrl) {
                const elem = { url: pwUrl, domain: pwUrlDomain || "" };
                controlAddition({
                  type: "passwordsUrl",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addPwUrls(elem);
                      setPwUrl("");
                      setPwUrlDomain("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setPwUrl(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <MDInput
            type="input"
            label="Password domain"
            fullWidth
            value={pwUrlDomain}
            onKeyDown={(e) => {
              if (e.key === "Enter" && pwUrl) {
                const elem = { url: pwUrl, domain: pwUrlDomain || "" };
                controlAddition({
                  type: "passwordsUrl",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addPwUrls(elem);
                      setPwUrl("");
                      setPwUrlDomain("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setPwUrlDomain(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (pwUrl) {
                  const elem = { url: pwUrl, domain: pwUrlDomain || "" };
                  controlAddition({
                    type: "passwordsUrl",
                    content: elem,
                  })
                    .then((res) => {
                      if (res) {
                        addPwUrls(elem);
                        setPwUrl("");
                        setPwUrlDomain("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputPwUrlApp() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.passwords_app_domains_explanation")}
        </MDTypography>
        <div style={{ position: "relative", display: "flex" }}>
          <MDInput
            type="input"
            label="Password url app"
            fullWidth
            value={pwUrlApp}
            onKeyDown={(e) => {
              if (e.key === "Enter" && pwUrlApp) {
                const elem = { domain: pwUrlApp || "", wildcard: wildcardPwApp };
                addPwUrlsApps(elem);
                setPwUrlApp("");
              }
            }}
            onChange={(e) => {
              setPwUrlApp(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          {/* <div
            style={{
              marginBottom: "30px",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              value={wildcardPwApp}
              onChange={() => {
                setWildcardPwApp(!wildcardPwApp);
              }}
            />{" "}
            <span style={{ fontSize: "14px", marginLeft: "10px" }}>Cookie wildcard</span>
          </div> */}
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (pwUrlApp) {
                  const elem = { domain: pwUrlApp || "", wildcard: wildcardPwApp };
                  addPwUrlsApps(elem);
                  setPwUrlApp("");
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
          display: "relative",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div style={{ position: "relative", marginRight: "10px", marginBottom: "30px" }}>
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  function viewArrayElements(elems, removeFunction, type) {
    return elems.map((el, index) => (
      <div key={`${type}-el-${index.toString()}`} className="clientsViewArrayElement">
        {el}
        <IconButton
          onClick={() => {
            removeFunction(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayElementsWebhooks() {
    return webhooks.map((el, index) => (
      <div key={`webhooks-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} - {el.auth} - {el.contentType}
        <IconButton
          onClick={() => {
            removeWebhook(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayCookies() {
    return cookies.map((el, index) => (
      <div key={`cookies-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} / {el.name} {el.wildcard ? "/ Con sottodomini" : ""}
        <IconButton
          onClick={() => {
            removeCookies(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayCookiesClient() {
    return cookiesClient.map((el, index) => (
      <div key={`cookies-client-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} / {el.name} / {el.user}
        <IconButton
          onClick={() => {
            removeCookiesClient(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayPwUrls() {
    return pwUrls.map((el, index) => (
      <div key={`password-urls-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} / {el.domain || "*"}
        <IconButton
          onClick={() => {
            removePwUrls(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayPwUrlsApp() {
    return pwUrlsApps.map((el, index) => (
      <div key={`password-urls-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.domain} {el.wildcard ? "/ Con sottodomini" : ""}
        <IconButton
          onClick={() => {
            removePwUrlsApps(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function serviceDescription() {
    let description;
    switch (serviceName) {
      case "premium":
        description = "*This is a premium service";
        break;
      case "combo":
        description = "*You selected the combo service";
        break;
      case "stealer":
        description = "*This is the stealer description";
        break;
      case "combo_potential":
        description = "*Combo potential description";
        break;
      case "empty":
        description = "*No services";
        break;
      default:
        description = "*This is a premium service";
    }

    return (
      <Typography fontSize="small" style={{ marginBottom: "30px" }}>
        {description}
      </Typography>
    );
  }

  return (
    <DashboardLayout>
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.customer_created")}
          success
          onConfirm={() => {
            setPopup(false);
            navigate("/clientlist");
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.customer_created_text")}
        </SweetAlert>
      ) : null}
      {popupUrlAlready ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.url_already_in_title")}
          error
          onConfirm={() => {
            setPopupUrlAlready(false);
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.url_already_in_text")}
        </SweetAlert>
      ) : null}
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card style={{ minWidth: "700px" }}>
            <MDBox variant="gradient">
              <MDBox px={5} pt={5}>
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox>
                    {getInput(t("admin.name"), setName, name)}
                    {getInput(t("admin.vatid_cf"), setVatId, vatId)}
                    {getCalendar(expiration, setExpiration, t("admin.expiration"))}
                    {getCalendar(start, setStart, t("admin.contract_start"))}
                    <div>
                      {getInputArrayEmail(
                        t("admin.email"),
                        setEmailInput,
                        addEmail,
                        emailInput,
                        "email",
                        ""
                      )}
                      {email && email.length > 0 ? (
                        <div className="clientsViewArrayContainer">
                          {viewArrayElements(email, removeEmail, "email")}
                        </div>
                      ) : null}
                      <hr className="add-customer-line" />
                    </div>
                    <div>
                    {getInputArrayWebhook()}
                    {webhooks && webhooks.length > 0 ? (
                        <div className="clientsViewArrayContainer">
                          {viewArrayElementsWebhooks()}
                        </div>
                      ) : null}
                      <hr className="add-customer-line" />
                    </div>
                    {service.includes("stealer") ? (
                      <div>
                        {getInputArray(
                          t("admin.passwords_domains"),
                          setPasswordurl,
                          addPasswords,
                          passwordurl,
                          "passwords",
                          t("admin.passwords_domains_explanation"),
                          passwordurlError,
                          setPasswordurlError
                        )}
                        {passwords && passwords.length > 0 ? (
                          <div className="clientsViewArrayContainer">
                            {viewArrayElements(passwords, removePasswords, "passwords")}
                          </div>
                        ) : null}
                        <hr className="add-customer-line" />
                        {getInputArray(
                          t("admin.usernames"),
                          setUsername,
                          addUsernames,
                          username,
                          "usernames",
                          t("admin.usernames_explanation"),
                          usernameError,
                          setUsernameError
                        )}
                        {usernames && usernames.length > 0 ? (
                          <div className="clientsViewArrayContainer">
                            {viewArrayElements(usernames, removeUsernames, "usernames")}
                          </div>
                        ) : null}
                        <hr className="add-customer-line" />
                        {getInputPwUrl()}
                        {pwUrls && pwUrls.length > 0 ? (
                          <div className="clientsViewArrayContainer">{viewArrayPwUrls()}</div>
                        ) : null}
                        <hr className="add-customer-line" />
                        {getInputPwUrlApp()}
                        {pwUrlsApps && pwUrlsApps.length > 0 ? (
                          <div className="clientsViewArrayContainer">{viewArrayPwUrlsApp()}</div>
                        ) : null}
                        <hr className="add-customer-line" />
                        {getInputCookie()}
                        {cookies && cookies.length > 0 ? (
                          <div className="clientsViewArrayContainer">{viewArrayCookies()}</div>
                        ) : null}
                        <hr className="add-customer-line" />
                        {getInputCookieClient()}
                        {cookiesClient && cookiesClient.length > 0 ? (
                          <div className="clientsViewArrayContainer">
                            {viewArrayCookiesClient()}
                          </div>
                        ) : null}
                        {getInputArray(
                          t("admin.ips"),
                          setIpInput,
                          addIp,
                          ipInput,
                          "ip",
                          t("admin.ips_explanation"),
                          errorIp,
                          setErrorIp
                        )}
                        {ip && ip.length > 0 ? (
                          <div className="clientsViewArrayContainer">
                            {viewArrayElements(ip, removeIp, "ips")}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {service.includes("combo") ? (
                      <div>
                        <hr className="add-customer-line" />
                        {getInputArray(
                          t("admin.combos"),
                          setCombo,
                          addCombos,
                          combo,
                          "combos",
                          t("admin.combos_explanation"),
                          comboError,
                          setComboError
                        )}
                        {combos && combos.length > 0 ? (
                          <div className="clientsViewArrayContainer">
                            {viewArrayElements(combos, removeCombos, "combos")}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <hr className="add-customer-line" />
                    {service.includes("stealer") ? (
                      <div>
                        {getInputArray(
                          t("admin.creditcards"),
                          setCreditcard,
                          addCreditcards,
                          creditcard,
                          "creditcards",
                          t("admin.creditcards_explanation"),
                          creditcardError,
                          setCreditcardError,
                          validateCC
                        )}
                        {creditcards && creditcards.length > 0 ? (
                          <div className="clientsViewArrayContainer">
                            {viewArrayElements(creditcards, removeCreditcards, "creditcards")}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {service.includes("ransomware") ? (
                      <div>
                        <hr className="add-customer-line" />
                        {getInputRansomwares()}
                        {ransomwares && ransomwares.length > 0 ? (
                          <div className="clientsViewArrayContainer">{viewArrayRansomwares()}</div>
                        ) : null}
                      </div>
                    ) : null}
                    {getSelect(["active", "inactive"], setStatus, status)}
                    {getSelect(
                      ["premium", "combo", "stealer", "combo_potential", "empty"],
                      editService,
                      serviceName
                    )}
                    {serviceDescription()}
                    <input
                      type="checkbox"
                      style={{ marginBottom: "30px" }}
                      checked={hasInsurance}
                      onChange={() => {
                        setHasInsurance(!hasInsurance);
                      }}
                    />{" "}
                    <span style={{ fontSize: "14px" }}>{t("admin.has_insurance_service")}</span>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox px={5} pb={5}>
              <MDButton
                onClick={() => {
                  if (name) {
                    const clientToCreate = {
                      name,
                      vatId: vatId || "",
                      status,
                      service,
                      ip,
                      serviceName,
                      ransomwares,
                      contractExpiration: expiration || null,
                      contractStart: start || null,
                    };
                    if (expiration) {
                      // fill serviceDetails
                      if (service?.length > 0) {
                        const newServiceDetail = service.map((el) => ({ service: el, expiration }));
                        clientToCreate.serviceDetail = newServiceDetail;
                      }
                    }
                    if (hasInsurance) {
                      clientToCreate.service.push("insurance");
                    }
                    if (service.includes("stealer")) {
                      clientToCreate.passwords = passwords;
                      clientToCreate.cookies = cookies;
                      clientToCreate.usernames = usernames;
                      clientToCreate.cookiesClient = cookiesClient;
                      clientToCreate.passwordsUrl = pwUrls;
                      clientToCreate.passwordsApps = pwUrlsApps;
                      clientToCreate.creditcards = creditcards;
                    } else {
                      clientToCreate.passwords = [];
                      clientToCreate.cookies = [];
                      clientToCreate.usernames = [];
                      clientToCreate.cookiesClient = [];
                      clientToCreate.passwordsUrl = [];
                      clientToCreate.passwordsApps = [];
                      clientToCreate.creditcards = [];
                    }
                    if (service.includes("combo")) {
                      clientToCreate.combolists = combos;
                    } else {
                      clientToCreate.combolists = [];
                    }
                    addClient(clientToCreate)
                      .then((res) => {
                        // eslint-disable-next-line
                        // console.log("cliente creato", res);
                        if (res) {
                          const logToSend = {
                            email: user && user.email ? user.email : "",
                            type: `Created new client ${name}`,
                            date: new Date(),
                          };
                          addLog(logToSend);
                          setName("");
                          setVatId("");
                          setCookieurl("");
                          setPasswordurl("");
                          setCookiename("");
                          setCookies([]);
                          setCookiesClient([]);
                          setStatus("active");
                          setServiceName("premium");
                          setService(["stealer", "ransomware", "combo"]);
                          setUsernames([]);
                          setCombos([]);
                          setPwUrls([]);
                          setIp([]);
                          setPwUrlsApps([]);
                          setUsername("");
                          setCreditcard("");
                          setCreditcards([]);
                          setRansomwares([]);
                          setRansomwareCompanySize("-");
                          setRansomwareCountry("");
                          setRansomwareDomain("");
                          setRansomwareGang("");
                          setRansomwareSector("");
                          setRansomwareVictim("");
                          setCombo("");
                          setPwUrl("");
                          setPwUrlDomain("");
                          setPasswords([]);
                          setPopup(true);
                        } else {
                          setErrorMessage(t("admin.error_create_customer_text"));
                        }
                      })
                      .catch((err) => {
                        // eslint-disable-next-line
                        console.log("errore creazione utente", err);
                        setErrorMessage(t("admin.error_create_customer_text"));
                      });
                  } else {
                    setErrorMessage(t("admin.error_create_customer_text_data"));
                  }
                }}
                size="medium"
                color="info"
                style={{ background: "#2C74E1" }}
                disableRipple
              >
                {t("add")}
              </MDButton>
            </MDBox>
            {errorMessage ? (
              <MDBox px={5} pb={5} color="primary" fontSize="medium">
                {errorMessage}
              </MDBox>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddClient;

const alerts = {
  en: {
    translation: {
      title: "Alerts",
      elements_involved: "Elements involved in the alert: ",
    },
  },
  it: {
    translation: {
      title: "Notifiche",
      elements_involved: "Elementi interessati dalla notifica: ",
    },
  },
};

export default alerts;

const analytics = {
  en: {
    translation: {
      your_overview: "Your overview",
      no_alerts: "No alerts found!",
      no_reports: "No reports found!",
      global_overview: "Global overview",
      title_credentials: "Stolen credentials analyzed",
      last_week: "last week",
      last_two_weeks: "last 2 weeks",
      title_darkweb_sites: "Active Darkweb sites scanned",
      last_24h: "last 24h",
      title_stolen_credentials: "Stolen identity",
      credentials_question_mark:
        "An overview of the stolen credentials that Defenda has analysed over the past two weeks, day by day, thanks to the numerous sources monitored.",
      title_ransomware_attacks: "Ransomware attacks",
      worldwide: "worldwide",
      ransomware_attacks: "Ransomware Incident",
      last_12_months: "last 12 months",
      ransomware_question_mark:
        "An overview of the ransomware attacks Defenda has become aware of over the past year, month by month.",
      alerts: "Alerts",
      title_attacks_trend: "Attacks trend",
      title_stealer_cookie_found: "Stolen credentials risk",
      title_credentials_found: "Stolen credentials risk",
      text_credentials_found:
        "credentials were found on attacks on your account, credentials may have been compromised",
      text_stealer_cookie_found:
        "We have found an attack on your account, a cookie may have been compromised",
      title_stealer_password_found: "Stolen credentials risk",
      text_stealer_password_found:
        "We have found an attack on your account, a password may have been compromised",
      open: "Open",
      report_pending: "Your report is not ready yet",
      report_error: "Something went wront while creating your report",
      details_stealer: "Open details attack",
      latest_reports: "Latest report requests",
      title_ransomware: "Ransomware risk",
      text_ransomware: "A ransomware with your paramethers has been found",
    },
  },
  it: {
    translation: {
      your_overview: "La tua panoramica",
      no_alerts: "Nessuna notifica trovata!",
      no_reports: "Nessuna richiesta di report!",
      global_overview: "Panoramica nel mondo",
      title_credentials: "Credenziali rubate analizzate",
      last_week: "nell'ultima settimana",
      last_two_weeks: "nelle ultime 2 settimane",
      title_darkweb_sites: "Siti nel darkweb scansionati",
      last_24h: "nelle ultime 24 ore",
      title_stolen_credentials: "Identità rubate",
      credentials_question_mark:
        "Una panoramica delle credenziali rubate che Defenda ha analizzato nelle ultime due settimane, giorno per giorno, grazie alle numerose fonti monitorate.",
      title_ransomware_attacks: "Attacchi ransomware",
      worldwide: "nel mondo",
      ransomware_attacks: "Attacchi Ransomware",
      last_12_months: "negli ultimi 12 mesi",
      ransomware_question_mark:
        "Una panoramica degli attacchi ransomware di cui Defenda è venuta a conoscenza nell’ultimo anno, mese per mese.",
      title_attacks_trend: "Andamento attacchi",
      alerts: "Notifiche",
      title_stealer_cookie_found: "Rischio di furto credenziali",
      title_credentials_found: "Rischio di furto credenziali",
      text_credentials_found:
        "credenzali sono state trovate in attacchi sul tuo account, le credenziali potrebbero essere state compromesse",
      text_stealer_cookie_found:
        "Abbiamo riscontrato un attacco nel tuo account, un cookie potrebbe essere stato compromesso",
      title_stealer_password_found: "Rischio di furto credenziali",
      text_stealer_password_found:
        "Abbiamo riscontrato un attacco nel tuo account, una password potrebbe essere stato compromesso",
      open: "Apri",
      report_pending: "Il report non è ancora pronto",
      report_error: "Qualcosa è andato storto nella creazione del report",
      details_stealer: "Apri dettaglio attacco",
      latest_reports: "Ultime richieste di report",
      title_ransomware: "Rischio ransomware",
      text_ransomware: "Un ransomware con i tuoi parametri è stato trovato",
    },
  },
};

export default analytics;
